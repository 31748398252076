import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage';
import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import style from "scss/pages/servizi.module.scss";
import $ from 'jquery'
import Lines from "components/lines";
import Image from "components/image";
import ShapeLogo from "images/pittogramma.svg"
import digitalMarketingImage from "images/services/digital-marketing.png"
import appImage from "images/services/app.png"
import ecommerceImage from "images/services/ecommerce.png"
import websiteImage from "images/services/website.png"
import ArrowDown from 'components/arrowDown/index';

class Servizi extends React.Component {

  componentDidMount(){

    const fullpage = require("fullpage.js");

    new fullpage('#fullpage', {
      sectionsColor: ['#141414','#ee3537', '#D9D9D9', '#303030', '#FFFFFF','#141414'],
      sectionSelector: '.section-slide'
    });
  }

  componentWillUnmount(){
    fullpage_api.destroy('all');
  }

  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Servizi" description={'Realizziamo e-commerce, siti internet, progetti di automation a Venezia. Affidati alla nostra web agency per la tua strategia SEO; SEM e per vendere online!'}/>

        <div className={style.shapeLogo}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <div className={style.shapeLogo__content}>
                  <ShapeLogo />
                  <img src={digitalMarketingImage} className={style.shapeLogo__content__icon_digitalMarketing} />
                  <img src={appImage} className={style.shapeLogo__content__icon_app} />
                  <img src={ecommerceImage} className={style.shapeLogo__content__icon_ecommerce} />
                  <img src={websiteImage} className={style.shapeLogo__content__icon_website} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="fullpage">

          <div class="section-slide">
            <div className={style.header}>
              <Lines />
              <div className={'container'}>
                <div className={'row'}>

                  <div className={'col-md-10'}>
                    <h1 className={'has-color-elements-dark'}><span className={'has-color-primary-negative'}>Un unico Interlocutore, </span><br /><span className={'has-color-dinamiza'}>una rete che collabora</span></h1>
                  </div>

                </div>

                <div className="row">
                  <div className={'offset-md-4 col-md-8 image'}>
                    <div className={style.header__payoff}>
                      <hr />
                      <h4><span>Cosa facciamo</span></h4>
                    </div>
                    <p className={`has-color-elements-dark`}>Gestiamo diverse tipologie di progetti digitali. Siamo un team di esperti focalizzati sulla parte tecnologica, ma collaboriamo con una rete selezionata di partner specializzati laddove occorre una competenza verticale.</p>
                    <p className={`has-color-elements-dark`}>Diventiamo parte integrante del progetto digitale del cliente, proponendo attivamente soluzioni e idee che portino valore all'idea. Ci poniamo come System integrator tra le tecnologie web e gli strumenti aziendali, confrontandoci quotidianamente con marketer e sistemisti.</p>
                  </div>
                </div>

              </div>
            </div>
            <ArrowDown />
          </div>

          <div className={`section-slide`}>
            <div className={style.section_slide}>
            <div className={'container'}>
              <div className={'row'}>
                <div className={'col-md-6 offset-md-6'}>
                  <div className={'title'}>
                    <h2><span>01.</span>Applicazioni Web</h2>
                  </div>
                  <p>Progettiamo e sviluppiamo applicazioni web based dall'interfaccia user friendly, integrabili con i software e i sistemi aziendali in uso. Ogni funzionalità è frutto di un processo di progettazione accurato.</p>
                  <p>
                    <ul>
                      <li>Software Architecture</li>
                      <li>Dashboard Design</li>
                      <li>UX design mobile e desktop</li>
                      <li>UI - web design</li>
                      <li>Web development</li>
                      <li>Integrazione CRM-ERP</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            </div>
            <ArrowDown />
          </div>

          <div className={`section-slide`}>
            <div className={style.section_slide}>
            <div className={'container'}>
              <div className={'row'}>
                <div className={'col-md-6 offset-md-6'}>
                  <div className={'title'}>
                    <h2><span>02.</span>Website</h2>
                  </div>
                  <p>Guidiamo l'utente nella conoscenza del brand e dei suoi prodotti/servizi attraverso un percorso di navigazione immediato ed emozionante. Partiamo dall'analisi delle interazioni tra le persone e l'azienda attraverso i diversi canali.</p>
                  <p>
                    <ul>
                      <li>UX design mobile e desktop</li>
                      <li>UI - web design</li>
                      <li>SEO</li>
                      <li>Lead generation</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            </div>
            <ArrowDown />
          </div>

          <div className={`section-slide`}>
            <div className={style.section_slide}>
              <div className={'container'}>
                <div className={'row'}>
                  <div className={'col-md-6 offset-md-6'}>
                    <div className={'title'}>
                      <h2><span>03.</span>Ecommerce</h2>
                    </div>
                    <p>Mappiamo la customer journey per semplificare i processi d'acquisto. Integriamo ERP e CRM in uso con la piattaforma ecommerce e utilizziamo sistemi di business intelligence per aumentare le performance.</p>
                    <p>
                      <ul>
                        <li>UX design mobile e desktop</li>
                        <li>UI - web design</li>
                        <li>Web development</li>
                        <li>Integrazione CRM-ERP</li>
                        <li>Marketing Automation</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ArrowDown />
          </div>

          <div className={`section-slide`}>
            <div className={style.section_slide}>
              <div className={'container'}>
                <div className={'row'}>
                  <div className={'col-md-6 offset-md-6'}>
                    <div className={'title'}>
                      <h2><span>04.</span>Digital marketing</h2>
                    </div>
                    <p>Combiniamo analisi, strategia e azione per trasformare il tuo pubblico in contatti e i tuoi clienti in ambassador. Non costruiamo storie, generiamo valore per il tuo business.</p>
                    <p>
                      <ul>
                        <li>Lead generation</li>
                        <li>Analisi dati</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ArrowDown />
          </div>

          <div class="section-slide">
            <div className={style.last_section}>
              <Lines />

              <div className={'container'}>
                <div className={'row'}>

                  <div className={'col-md-7'}>
                    <h1 className={'has-color-elements-dark'}><span className={'has-color-primary-negative'}>Lavoriamo con:</span></h1>
                    <div className={style.header__payoff}>
                      <hr />
                      <h4><span>Drupal, Prestashop, Wordpress, Shopify, React, Symfony, Php</span></h4>
                    </div>
                    <p className={`has-color-elements-dark`}>ma la nostra curiosità per nuovi linguaggi e nuove piattaforme non si ferma qui. Troviamo la soluzione più adatta in base al progetto da realizzare.</p>
                  </div>

                </div>

                <div className={style.last_section__second}>
                  <div className="row">
                    <div className={'offset-md-4 col-md-6 image'}>
                      <h1 className={'has-color-elements-dark'}><span className={'has-color-primary-negative'}>Diventa partner</span></h1>
                      <p className={`has-color-elements-dark`}>Siamo bravi, ma non sappiamo fare tutto, siamo convinti che per alzare il livello dei progetti sia sempre più necessario essere verticali su qualcosa e collaborare con altre aziende complementari.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>

   

      </Layout>
    )
  }
}

export default Servizi
